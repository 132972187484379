export const downloadCSV = async (
  filename: string,
  fetchRowObjects: () => Promise<[string[]]>,
) => {
  const rowsArray = (await fetchRowObjects()) || [];

  for (const rowIndex in rowsArray) {
    for (const cellIndex in rowsArray[rowIndex]) {
      rowsArray[rowIndex][cellIndex] = `"${rowsArray[rowIndex][cellIndex]}"`;
    }
  }

  const csvRows = (rowsArray || []).map(
    (row) => (row.join(',') || '') + '\r\n',
  );

  // the "\uFEFF" is a trick to support arabic and non-ascii chars in the csv
  const csvData = new Blob(['\uFEFF', ...csvRows], {
    type: 'text/csv; charset=utf-8',
  });

  const csvURL = URL.createObjectURL(csvData);
  const link = document.createElement('a');
  link.href = csvURL;
  link.download = `${filename}.csv`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};
